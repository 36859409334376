// Vendor
import React from "react";
import { useTranslation } from "react-i18next";

// Style
import { Level, Name, Wrapper } from "./Languages.styled";

export default function Languages() {
  const { t } = useTranslation("main");

  return (
    <Wrapper>
      <div>
        <Name>{t("languages.spanish")}</Name>
        <Level>{t("languages.nativeLevel")}</Level>
      </div>
      <div>
        <Name>{t("languages.valencian")}</Name>
        <Level>
          {t("languages.nativeLevel")} · {t("languages.B2Certificate")}
        </Level>
      </div>
      <div>
        <Name>{t("languages.english")}</Name>
        <Level>{t("languages.intermediateLevel")}</Level>
      </div>
    </Wrapper>
  );
}
