// Vendor
import { useTranslation } from "react-i18next";

// Style
import { Item, ItemName, Wrapper } from "./Hire.styled";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faSquareArrowUpRight,
} from "@fortawesome/free-solid-svg-icons";

export default function HireMeBox() {
  const { t } = useTranslation("main");

  return (
    <Wrapper>
      <Item $id="location">
        <ItemName>{t("contact.locationLabel")}</ItemName>
        {t("contact.location")}
      </Item>
      <Item $id="web">
        <ItemName>{t("contact.webLabel")}</ItemName>
        <a target="_blank" href="https://frantorregrosa.me">
          frantorregrosa.me <FontAwesomeIcon icon={faSquareArrowUpRight} />
        </a>
      </Item>
      <Item $id="github">
        <ItemName>GitHub</ItemName>
        <a target="_blank" href="https://github.com/frantorreg">
          @frantorreg <FontAwesomeIcon icon={faSquareArrowUpRight} />
        </a>
      </Item>
      <Item $id="telegram">
        <ItemName>Telegram</ItemName>
        <a target="_blank" href="https://t.me/frantr">
          @frantr <FontAwesomeIcon icon={faTelegramPlane} />
        </a>
      </Item>
      <Item $id="phone">
        {" "}
        {/* TODO: warning */}
        <ItemName>{t("contact.phone")}</ItemName>
        <a href="tel:+34680316205">
          +34 680 316 205 <FontAwesomeIcon icon={faPhone} />
        </a>
      </Item>
      <Item $id="email">
        <ItemName>{t("contact.emailLabel")}</ItemName>
        <a href="mailto:contacte@frantorregrosa.me">
          contacto@frantorregrosa.me 📩
        </a>
      </Item>
    </Wrapper>
  );
}
