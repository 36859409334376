// Vendor
import styled from "styled-components";
import { queries } from "styles/mediaQueries.styled";

export const Wrapper = styled.div`
  width: 100%;

  @media print {
    padding: 45px 90px 0; // temporal
  }
`;

export const Background = styled.div`
  @media screen {
    width: 100%;
    height: 150px;
    background: #c2e59c;
    background: linear-gradient(to right, #64b3f4, #c2e59c);
  }
`;

export const Data = styled.div`
  padding: 0 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media screen {
    margin-top: -80px;
  }

  @media print {
    margin-top: 7.5rem;
  }
`;

export const Face = styled.img`
  width: 150px;
  border-radius: 100%;

  @media screen {
    border: 3px solid white;
    box-shadow: 4px 4px 8px #8080807a;
  }

  @media print {
    padding: 3px;
    background: #c2e59c;
    background: linear-gradient(to right, #64b3f4, #c2e59c);
  }
`;

export const Name = styled.h1`
  font-size: 2rem;
  font-weight: bold;
`;

export const Subtitle = styled.div`
  @media screen {
    font-size: 1.6rem;

    @media (${queries.mobile}) {
      font-size: 1.4rem;
    }
  }

  @media print {
    font-size: 1.2rem;
  }
`;

export const Description = styled.div`
  max-width: 750px;
  text-align: center;
  color: #717171;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.3;
`;
