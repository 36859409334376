// Vendor
import { useTranslation } from "react-i18next";

// Style
import {
  Background,
  Data,
  Description,
  Face,
  Name,
  Subtitle,
  Wrapper,
} from "./Header.styled";

export default function Header() {
  const { t } = useTranslation("main");

  return (
    <Wrapper>
      <Background />
      <Data>
        <Face src="https://secure.gravatar.com/avatar/5338dcd43ada152f033b638ceaada0af.jpg?d=retro&amp;s=400" />
        <Name>{t("introduction.title")}</Name>
        <Subtitle>
          <span
            dangerouslySetInnerHTML={{ __html: t("introduction.subtitle") }}
          />
        </Subtitle>
        <Description>
          <span
            dangerouslySetInnerHTML={{ __html: t("introduction.description") }}
          />
        </Description>
      </Data>
    </Wrapper>
  );
}
