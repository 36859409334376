// Vendor
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 12px 15px;
  background-color: white;
  border-radius: 5px;
`;

export const Cover = styled.div`
  margin-bottom: 30px;
`;

export const Section = styled.section`
  max-width: 800px;
  margin: 5px auto;
  padding: 40px 45px 15px;
  break-inside: avoid;

  @media (max-width: 500px) {
    margin: 0 auto;
    padding: 20px 20px;
  }

  h2 {
    font-size: 1.5rem;
    margin: 5px 0 15px;

    @media (max-width: 500px) {
      font-size: 1.3rem;
    }
  }
`;
