// Vendor
import styled, { css } from "styled-components";

export const Button = styled.button`
  padding: 6px 12px;
  background-color: #1565c0;
  border: 0;
  border-radius: 5px;
  color: white;
  cursor: pointer;

  ${(props) =>
    props.inverted &&
    css`
      background-color: transparent;
      border: 1px solid #1565c0;
      color: #1565c0;

      transition-duration: 150ms;

      &:hover {
        background-color: #e7eff9;
      }
    `}
`;
