// Vendor
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Name = styled.div`
  font-weight: bold;
`;

export const Level = styled.div`
  text-transform: lowercase;
`;
