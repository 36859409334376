// Vendor
import React from "react";

// Style
import { Knowledge, Bar, Improving, Wrapper } from "./Skill.styled";

export default function Skill({ name, level, improving }) {
  return (
    <Wrapper>
      <span>{name}</span>
      {level && (
        <Knowledge>
          <Bar className={level} />
        </Knowledge>
      )}
      {/*improving && (
        <Improving title='Estic centrat en millorar les meues habilitats en este aspecte o tecnologia.'>
          <FontAwesomeIcon icon={faBookOpen} /> millorant
        </Improving>
      )*/}
    </Wrapper>
  );
}
