// Vendor
import React from "react";
import { useTranslation } from "react-i18next";

// Components
import Skill from "components/Skill/Skill";

// Styles
import { Categories, Category, Name } from "./Skills.styled";

export default function Skills() {
  const { t } = useTranslation("main");

  return (
    <Categories>
      <Category>
        <Name>
          <span dangerouslySetInnerHTML={{ __html: t("skills.frontEnd") }} />
        </Name>
        <Skill name="HTML 5" />
        <Skill name={t("skills.css")} />
        <Skill name="JavaScript (ES6)" />
        <Skill name="React" />
        <Skill name="Redux" />
        <Skill name="Styled Components" />
        <Skill name="Testing Library" improving />
        <Skill name="Relay" />
        <Skill name="Webpack" />
        <Skill name="Progresive Web App" />
        <Skill name="Accessibilitat" improving />
      </Category>
      <Category>
        <Name>
          <span dangerouslySetInnerHTML={{ __html: t("skills.backEnd") }} />
        </Name>
        <Skill name="Ruby" />
        <Skill name="Rails" />
        <Skill name="NodeJS" />
        <Skill name="MongoDB" />
        <Skill name="SQL" />
        <Skill name="Go" />
      </Category>
      <Category>
        <Name>
          <span dangerouslySetInnerHTML={{ __html: t("skills.devOps") }} />
        </Name>
        <Skill name="Git" />
        <Skill name="Docker" />
        <Skill name="Kubernetes" />
        <Skill name="GNU/Linux" />
      </Category>
      <Category>
        <Name>{t("skills.other")}</Name>
        <Skill name={t("skills.responsive")} />
        <Skill name={t("skills.componentsArchitecture")} />
        <Skill name={t("skills.security")} />
        <Skill name={t("skills.apiArchitectures")} />
        <Skill name={t("skills.websocketAndSSE")} />
        <Skill name={t("skills.regex")} />
      </Category>
    </Categories>
  );
}
