// Vendor
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 12px 15px;
  background-color: white;
  border-radius: 5px;
`;

export const Knowledge = styled.div`
  width: 50px;
  height: 5px;
  margin-top: 5px;
  background-color: #d8d8d8;
`;

export const Bar = styled.div`
  height: 100%;
  background-color: #8c8c8c;

  &.low {
    width: 20%;
  }

  &.mid-low {
    width: 40%;
  }

  &.mid {
    width: 60%;
  }

  &.mid-high {
    width: 80%;
  }

  &.high {
    width: 100%;
  }
`;

export const Improving = styled.div`
  margin-top: 5px;
  color: #4f4f4f;
  cursor: help;
  background-color: #b4e8d2;
  border-radius: 5px;
  display: inline-flex;
  padding: 5px 7px;
  gap: 8px;
  align-items: center;
`;
