// Vendor
import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import moment from "moment";

// Components
import Main from "components/Main/Main";

// Style
import "./App.scss";

// Translations
import "moment/locale/es";
import "moment/locale/ca";

class App extends Component {
  componentDidMount() {
    const { i18n } = this.props;

    const setLanguage = (locale) => {
      document.documentElement.setAttribute("lang", locale);
      moment.locale(locale);
      this.setState({ locale });
    };

    setLanguage(i18n.language);
    i18n.on("languageChanged", setLanguage);
  }

  render() {
    const { t, i18n, location } = this.props;

    return (
      <>
        <Helmet>
          <title>{t("app:name")}</title>
          <meta name="description" content={t("app:description")} />
          {i18n.options.safeWhitelist.map((language) => (
            <link
              key={language}
              rel="alternate"
              hrefLang={language}
              href={`${location.pathname}?hl=${language}`}
            />
          ))}
        </Helmet>
        <Switch>
          <Route path="/" component={Main} />
        </Switch>
      </>
    );
  }
}

export default withTranslation()(withRouter(App));
