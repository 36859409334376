// Vendor
import React from "react";
import { useTranslation } from "react-i18next";

// Components
import Episode from "components/Episode/Episode";
import Header from "components/Header/Header";
import HireMeBox from "components/HireMeBox/HireMeBox";
import Languages from "components/Languages/Languages";
import PrintingNotice from "components/PrintingNotice/PrintingNotice";
import Skills from "components/Skills/Skills";

// Style
import { Cover, Section } from "./Main.styled";

// Images
import { ReactComponent as AvptpLogo } from "images/organizations/avptp.svg";
import { ReactComponent as BrutalLogo } from "images/organizations/brutal.svg";
import { ReactComponent as OpenbankLogo } from "images/organizations/openbank.svg";
import { ReactComponent as CecotecLogo } from "images/organizations/cecotec.svg";
import { ReactComponent as DideLogo } from "images/organizations/dide.svg";
import { ReactComponent as HabtiumLogo } from "images/organizations/habtium.svg";

export default function Main() {
  const { t } = useTranslation("main");

  return (
    <div>
      <Cover>
        <Header />
        <HireMeBox />
        <PrintingNotice />
      </Cover>
      <Section>
        <h2>{t("skills.title")}</h2>
        <Skills />
      </Section>
      <Section>
        <h2>{t("languages.title")}</h2>
        <Languages />
      </Section>
      <Section>
        <h2>{t("positions.experience")}</h2>
        <Episode
          title="Openbank"
          logo={<OpenbankLogo />}
          url="https://www.openbank.es/"
          position={t("positions.frontEnd")}
          startDate={{ y: 2021, M: 11, d: 10 }}
          stack={["react", "redux", "microfrontends", "styled components"]}
        />
        <Episode
          title="Cecotec"
          logo={<CecotecLogo />}
          url="https://cecotec.es/"
          position={t("positions.frontEnd")}
          startDate={{ y: 2021, M: 2, d: 22 }}
          endDate={{ y: 2021, M: 11, d: 9 }}
          achievements={t("achievements.cecotec")}
          stack={["react", "microfrontends", "kubernetes", "aws"]}
        />
        <Episode
          title="Dide"
          logo={<DideLogo />}
          url="https://dide.app"
          position={t("positions.fullStack")}
          startDate={{ y: 2017, M: 5, d: 1 }}
          endDate={{ y: 2021, M: 2, d: 21 }}
          achievements={t("achievements.dide")}
          stack={["react", "ruby on rails", "mongodb", "kubernetes", "azure"]}
        />
      </Section>
      <Section>
        <h2>{t("positions.projects")}</h2>
        <Episode
          title="Brutal"
          logo={<BrutalLogo />}
          url="https://brutal.systems/"
          position={t("positions.frontEnd")}
          startDate={{ y: 2022, M: 3, d: 1 }}
          endDate={null}
          achievements={t("achievements.brutal")}
          stack={["react", "scss"]}
        />
        <Episode
          title={t("experience.personalWeb")}
          logo={
            <img
              style={{ borderRadius: "100%" }}
              src="https://secure.gravatar.com/avatar/5338dcd43ada152f033b638ceaada0af.jpg?d=retro&amp;s=400"
            />
          }
          url="https://frantorregrosa.me"
          repoUrl="https://github.com/frantorreg/showcase"
          contributionsUrl="https://github.com/frantorreg/showcase/commits?author=frantorreg"
          position={t("positions.frontEnd")}
          startDate={{ y: 2021, M: 0, d: 1 }}
          endDate={null}
          achievements={t("achievements.personalWeb")}
          stack={["react", "styled components"]}
        />
        <Episode
          title="Associació Valenciana pel Transport Públic"
          logo={<AvptpLogo />}
          url="https://avptp.org"
          repoUrl="https://github.com/avptp/face"
          contributionsUrl="https://github.com/avptp/face/commits?author=frantorreg"
          position={`${t("positions.secretaryAndCoFounder")} · ${t(
            "positions.contentCreator"
          )} · ${t("positions.frontEnd")}`}
          startDate={{ y: 2018, M: 3, d: 1 }}
          stack={["react", "nextjs", "scss"]}
        />
        <Episode
          title="Habtium"
          logo={<HabtiumLogo />}
          url="https://habtium.es"
          position={t("positions.fullStack")}
          startDate={{ y: 2013, M: 9, d: 1 }}
          endDate={{ y: 2016, M: 5, d: 1 }}
          stack={["jquery", "php", "mariadb"]}
        />
      </Section>
    </div>
  );
}
