// Vendor
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  max-width: 800px;
  margin: 60px auto;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;

  @media screen {
    background-color: #e5f4f7;
    padding: 25px;
    gap: 15px;
    justify-content: space-evenly;
    //justify-content: space-between;
  }

  @media print {
    background-color: #e5f4f780;
    padding: 35px;
    gap: 30px 80px;
    justify-content: center;
  }
`;

export const Item = styled.div`
  font-weight: 500;
  line-height: 1.4;

  ${(props) =>
    (props.$id === "web" ||
      props.$id === "phone" ||
      props.$id === "telegram") &&
    css`
      @media screen {
        display: none;
      }
    `};

  /*
  ${(props) =>
    props.$id === "telegram" &&
    css`
      @media print {
        display: none;
      }
    `};

   */
`;

export const ItemName = styled.div`
  font-weight: 300;
`;
