// Vendor
import QRCode from "react-qr-code";
import moment from "moment";

// Style
import {
  Wrapper,
  ScreenWrapper,
  PrintWrapper,
  Icon,
  QRWrapper,
  Text,
  Title,
  DownloadButton,
} from "./PrintingNotice.styled";

// Icons
import { ReactComponent as DownloadIcon } from "images/icons/download.svg";

export default function PrintingNotice() {
  return (
    <Wrapper>
      <ScreenWrapper>
        <Icon>
          <DownloadIcon />
        </Icon>
        <Text>
          <Title>Descargar</Title>
          <p>
            Obtén este documento imprimiendo directamente la página desde tu
            navegador.
          </p>
          <ul>
            <li>
              A menos que lo necesites, evita imprimirlo en papel. Puedes
              guardarlo en PDF.
            </li>
            <li>Oriéntalo en vertical.</li>
            <li>
              Activa la opción «gráficos de fondo» o «imprimir fondo de
              pantalla».
            </li>
            <li>Desactiva las cabeceras y los pie de página.</li>
          </ul>
          <DownloadButton
            onClick={() =>
              window.print({ bUI: false, bSilent: true, bShrinkToFit: true })
            }
          >
            Imprimir
          </DownloadButton>
        </Text>
      </ScreenWrapper>
      <PrintWrapper>
        <QRWrapper>
          <QRCode
            size={60}
            fgColor="#595959"
            title="Acceder a frantorregrosa.me"
            value="https://frantorregrosa.me"
          />
        </QRWrapper>
        <Text>
          <p>
            Este documento se imprimió el día {moment().format("DD MMMM YYYY")},
            por lo que puede estar desactualizado en el momento de su lectura.
          </p>
          <p>
            Consulta la información actualizada en{" "}
            <a href="https://frantorregrosa.me">frantorregrosa.me</a>.
          </p>
        </Text>
      </PrintWrapper>
    </Wrapper>
  );
}
