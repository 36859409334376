// Vendor
import styled from "styled-components";

export const Categories = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Category = styled.div`
  padding: 10px;
  background-color: #f7f7f7;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 5px;
`;

export const Name = styled.div`
  width: 100%;
  font-size: 1.1rem;
  font-weight: 300;
  color: dimgrey;
`;
