// Vendor
import styled from "styled-components";
import { Button } from "components/base/Button/Button.styled";

export const Wrapper = styled.div`
  ul {
    padding: 0 0 0 25px;
  }

  padding: 0 20px;
`;

export const ScreenWrapper = styled(Wrapper)`
  max-width: 450px;
  margin: auto;

  display: flex;
  gap: 15px;

  @media print {
    display: none;
  }
`;

export const PrintWrapper = styled(Wrapper)`
  max-width: 500px;
  margin: auto;

  display: flex;
  gap: 15px;

  @media screen {
    display: none;
  }
`;

export const Icon = styled.div`
  //width: 35px;
  //font-size: 30px;
  //color: #646464;
  width: 50px;
  height: 50px;

  svg {
    width: 50px;
    height: 50px;
  }
`;

export const QRWrapper = styled.div`
  width: 60px;
`;

export const Text = styled.div`
  color: #595959;
  font-size: 0.9em;

  @media print {
    max-width: 375px;
  }
`;

export const Title = styled.h3`
  margin: 0 0 5px;
`;

export const DownloadButton = styled(Button).attrs({ inverted: true })``;
